import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  iIncome,
  iRecords,
} from 'src/app/mini-apps/expense-app/common/interface';
import { IncomeService } from '../../services/income.service';
import {
  AddIncomeAction,
  AddIncomeSuccessAction,
  DeleteIncomeAction,
  DeleteIncomeSuccessAction,
  GetIncomeAction,
  LoadIncomeAction,
  ResetIncomeStore,
  UpdateIncomeAction,
  UpdateIncomeSuccessAction,
} from '../actions/income.action';
import { ResetStore } from 'src/app/store/reset/reset.action';

@Injectable({
  providedIn: 'root',
})
export class IncomeEffects {
  actions$: Actions = inject(Actions);
  incomeService: IncomeService = inject(IncomeService);

  getIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetIncomeAction),
      exhaustMap(() =>
        this.incomeService.getIncome().pipe(
          filter((income) => income.length > 0),
          map((income: iIncome[]) => {
            return LoadIncomeAction({ income });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  addIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddIncomeAction),
      switchMap((params) =>
        this.incomeService.addIncome(params.income).pipe(
          map((income) => {
            if (!!income.tags) {
              income.tagsArray = income.tags.split('|');
            }

            return AddIncomeSuccessAction({ income: income });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  updateIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateIncomeAction),
      switchMap((params) =>
        this.incomeService.updateIncome(params.income).pipe(
          map((income) => {
            if (!!income.tags) {
              income.tagsArray = income.tags.split('|');
            }

            return UpdateIncomeSuccessAction({ income });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  deleteIncome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteIncomeAction),
      switchMap((params) =>
        this.incomeService.deleteIncome(params.income).pipe(
          map((income) => {
            return DeleteIncomeSuccessAction({ income });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );


  resetStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetStore),
      map(() => {
        return ResetIncomeStore();
      }),
    ),
  );
}
