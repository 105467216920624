import { Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { IIconUpload } from '../common/interface';
import { HttpService } from './http.service';
import { Observable, combineLatest, from } from 'rxjs';
import { tap, switchMap, startWith, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IconUploadService extends HttpService {
  // getIcons(): Observable<IIconUpload[]> {
  //   let isFromDB = false;
  //   let maxId: number = 0;

  //   return combineLatest([
  //     from(
  //       this.indexedDb.getAllFromDbForTableByUser<IIconUpload>('icon_uploads'),
  //     ),
  //     from(this.indexedDb.getMaxId('icon_uploads')),
  //   ]).pipe(
  //     map(([iconUploadFromDB, maxIdFromDB]) => {
  //       if (maxIdFromDB) {
  //         maxId = maxIdFromDB;
  //       }
  //       if (iconUploadFromDB && iconUploadFromDB.length > 0) {
  //         isFromDB = true;
  //       }

  //       return iconUploadFromDB;
  //     }),
  //     switchMap((iconUploadFromDB: IIconUpload[]) => {
  //       return this.get<IIconUpload[]>(
  //         'icon-upload/get-icons',
  //         false,
  //         { trackerTypeId: 1, maxId },
  //         Trackers.uploadIconForTags,
  //         iconUploadFromDB.length === 0,
  //       ).pipe(
  //         startWith(iconUploadFromDB),
  //         filter((i) => i.length > 0),
  //         map((iconUpload: IIconUpload[]) => {
  //           // *********** storing in indexed db ************

  //           // @ts-ignore
  //           iconUpload = structuredClone(
  //             iconUpload.filter((i) => i !== undefined),
  //           );
  //           // only do data manipulation when its coming from server
  //           if (isFromDB == false && iconUpload.length > 0) {
  //             // if data is coming from API then we stringify it and store it in indexed db
  //             this.indexedDb.upsertBulk('icon_uploads', iconUpload);
  //           }

  //           if (isFromDB === true) {
  //             isFromDB = false;
  //           }
  //           return iconUpload;
  //         }),
  //       );
  //     }),
  //   );
  // }

  getIcons(): Observable<IIconUpload[]> {
    return from(this.indexedDb.getMaxId('icon_uploads')).pipe(
      switchMap((maxIdFromDB) => {
        return this.get<IIconUpload[]>(
          'icon-upload/get-icons',
          false,
          { trackerTypeId: 1, maxId: maxIdFromDB },
          Trackers.uploadIconForTags,
          maxIdFromDB === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((iconUpload: IIconUpload[]) => {
            // @ts-ignore
            iconUpload = structuredClone(
              iconUpload.filter((i) => i !== undefined),
            );

            this.indexedDb.upsertBulk('icon_uploads', iconUpload);
            return iconUpload;
          }),
        );
      }),
    );
  }
  createIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/add-icon',
      formData,
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      }),
    );
  }
  updateIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/update-icon',
      formData,
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      }),
    );
  }
  deleteIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/delete-icon',
      formData,
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      }),
    );
  }
}
