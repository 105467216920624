import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { Trackers } from '../common/enum';
import { iGroup } from '../common/interface';
import { ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { IndexedDBService } from './indexedDb.service';
import { Observable, combineLatest, from } from 'rxjs';
import { tap, switchMap, startWith, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends HttpService {
  expenseEngine: ExpenseEngineService = inject(ExpenseEngineService);

  getGroups(trackerTypeId: Trackers): Observable<iGroup[]> {
    return from(this.indexedDb.getMaxId('groups')).pipe(
      switchMap((maxIdFromDB) => {
        return this.get<iGroup[]>(
          'group/get-groups',
          false,
          { trackerTypeId, maxId: maxIdFromDB },
          Trackers.groups,
          maxIdFromDB === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((groups) => {
            // *********** storing in indexed db ************
            // @ts-ignore
            groups = structuredClone(groups.filter((i) => i !== undefined));

            this.indexedDb.upsertBulk('groups', groups);
            return groups;
          }),
        );
      }),
    );
  }
  createGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/add-group', formData);
  }
  updateGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/update-group', formData);
  }
  deleteGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/delete-group', formData);
  }
}
