import { Action, createReducer, on } from '@ngrx/store';
import { ResetUserInfo, SetUserInfo } from '../actions/user.actions';
import { IUser } from '../../../../common/app-interfaces';

const initialUserState: IUser = {} as IUser;

const _UserReducer = createReducer(
  initialUserState,
  on(SetUserInfo, (state, { user }) => ({
    id: user.id,
    uuid: user.uuid,
    username: user.username,
    createdAt: user.createdAt,
    deletedBy: user.deletedBy,
    extraInfo: user.extraInfo,
    isDeleted: user.isDeleted,
    lastLogin: user.lastLogin,
    updatedAt: user.updatedAt,
  })),
  on(ResetUserInfo, (state) => initialUserState),
);

export function UserReducer(state: IUser | undefined, action: Action): IUser {
  return _UserReducer(state, action);
}
