import { Injectable } from '@angular/core';
import { combineLatest, from, Observable } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Trackers } from '../common/enum';
import { iTags, iIcon, iIgnoreTags, iRecords } from '../common/interface';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class TagsService extends HttpService {
  tagIcons: iIcon = {};

  createIgnoreTag(tag: iIgnoreTags): Observable<iIgnoreTags> {
    return this.post<iIgnoreTags, iIgnoreTags>(
      'tags/create-ignore-tag',
      tag,
    ).pipe(
      tap((ignoreTags: iIgnoreTags) => {
        if (ignoreTags) {
          this.indexedDb.upsertBulk('ignore_tags', [ignoreTags]);
        }
      }),
    );
  }

  updateIgnoreTag(tag: iIgnoreTags): Observable<iIgnoreTags> {
    return this.post<iIgnoreTags, iIgnoreTags>(
      'tags/update-ignore-tag',
      tag,
    ).pipe(
      tap((ignoreTags: iIgnoreTags) => {
        if (ignoreTags) {
          this.indexedDb.upsertBulk('ignore_tags', [ignoreTags]);
        }
      }),
    );
  }

  addTagForIcon(tag: iTags): Observable<iTags> {
    return this.post<iTags, iTags>('tags/add-tag-for-icon', tag).pipe(
      map((tag) => {
        this.tagIcons[tag.name] = tag.iconUrl;
        return tag;
      }),
    );
  }

  // getIgnoreTags(): Observable<iIgnoreTags[]> {
  //   let isFromDB = false;
  //   let maxId: number = 0;
  //   return combineLatest([
  //     from(this.indexedDb.getAllFromDbForTableByUser<iIgnoreTags>('ignore_tags')),
  //     from(this.indexedDb.getMaxId('ignore_tags')),
  //   ]).pipe(
  //     map(([ignoreTagsFromDB, maxIdFromDB]) => {
  //       if (maxIdFromDB) {
  //         maxId = maxIdFromDB;
  //       }
  //       if (ignoreTagsFromDB && ignoreTagsFromDB.length > 0) {
  //         isFromDB = true;
  //       }

  //       return ignoreTagsFromDB;
  //     }),
  //     switchMap((ignoreTagsFromDB: iIgnoreTags[]) => {
  //       return this.get<iIgnoreTags[]>(
  //         'tags/get-ignore-tags',
  //         false,
  //         { maxId },
  //         Trackers.ignoreTags,
  //         ignoreTagsFromDB.length === 0,
  //       ).pipe(
  //         startWith(ignoreTagsFromDB),
  //         filter((i) => i.length > 0),
  //         map((ignoreTags: iIgnoreTags[]) => {
  //           // *********** storing in indexed db ************

  //           // @ts-ignore
  //           ignoreTags = structuredClone(
  //             ignoreTags.filter((i) => i !== undefined),
  //           );
  //           // only do data manipulation when its coming from server
  //           if (isFromDB == false && ignoreTags.length > 0) {
  //             // if data is coming from API then we stringify it and store it in indexed db
  //             this.indexedDb.upsertBulk('ignore_tags', ignoreTags);
  //           }

  //           if (isFromDB === true) {
  //             isFromDB = false;
  //           }
  //           return ignoreTags;
  //         }),
  //       );
  //     }),
  //   );
  // }

  getIgnoreTags(): Observable<iIgnoreTags[]> {
    return from(this.indexedDb.getMaxId('ignore_tags')).pipe(
      switchMap((maxIdFromDB) => {
        return this.get<iIgnoreTags[]>(
          'tags/get-ignore-tags',
          false,
          { maxId: maxIdFromDB },
          Trackers.ignoreTags,
          maxIdFromDB === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((ignoreTags: iIgnoreTags[]) => {
            // @ts-ignore
            ignoreTags = structuredClone(
              ignoreTags.filter((i) => i !== undefined),
            );

            this.indexedDb.upsertBulk('ignore_tags', ignoreTags);
            return ignoreTags;
          }),
        );
      }),
    );
  }
}
