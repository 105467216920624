import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { ReoccurringExpensesOrIncomeService } from '../../services/reoccurring-tracks.service';

import {
  GetReoccurringTracksAction,
  LoadReoccurringTracksAction,
  AddReoccurringTracksAction,
  AddReoccurringTracksSuccessAction,
  UpdateReoccurringTracksAction,
  UpdateReoccurringTracksSuccessAction,
  DeleteReoccurringTracksAction,
  DeleteReoccurringTracksSuccessAction,
  ResetReoccurringTracksStore,
} from '../actions/reoccurring-tracks.action';
import { ResetStore } from 'src/app/store/reset/reset.action';

@Injectable({
  providedIn: 'root',
})
export class ReoccurringTrackEffect {
  actions$: Actions = inject(Actions);
  trackService: ReoccurringExpensesOrIncomeService = inject(
    ReoccurringExpensesOrIncomeService,
  );

  getReoccurringTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetReoccurringTracksAction),
      exhaustMap(() =>
        this.trackService.getExpensesOrIncome().pipe(
          filter((tracks) => tracks.length > 0),
          map((tracks) => {
            return LoadReoccurringTracksAction({ reoccurringTracks: tracks });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  addReoccurringTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddReoccurringTracksAction),
      switchMap((params) =>
        this.trackService.addExpensesOrIncome(params.reoccurringTrack).pipe(
          map((track) => {
            if (!!track.tags) {
              track.tagsArray = track.tags.split('|');
            }
            return AddReoccurringTracksSuccessAction({
              reoccurringTrack: track,
            });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  updateReoccurringTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateReoccurringTracksAction),
      switchMap((params) =>
        this.trackService.updateExpensesOrIncome(params.reoccurringTrack).pipe(
          map((track) => {
            if (!!track.tags) {
              track.tagsArray = track.tags.split('|');
            }
            return UpdateReoccurringTracksSuccessAction({
              reoccurringTrack: track,
            });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  deleteReoccurringTrack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteReoccurringTracksAction),
      switchMap((params) =>
        this.trackService.deleteExpensesOrIncome(params.reoccurringTrack).pipe(
          map((track) => {
            return DeleteReoccurringTracksSuccessAction({
              reoccurringTrack: track,
            });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  resetStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetStore),
      map(() => {
        return ResetReoccurringTracksStore();
      }),
    ),
  );
}
