import { Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { IBudgetDetail } from '../common/interface';
import { HttpService } from './http.service';
import { Observable, combineLatest, from } from 'rxjs';
import { tap, switchMap, startWith, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BudgetService extends HttpService {
  // public getBudgetDetail(): Observable<IBudgetDetail[]> {
  //   let isFromDB = false;
  //   let maxId: number = 0;

  //   return combineLatest([
  //     from(this.indexedDb.getAllFromDbForTableByUser<IBudgetDetail>('budget_detail')),
  //     from(this.indexedDb.getMaxId('budget_detail')),
  //   ]).pipe(
  //     map(([budgetDetailFromDB, maxIdFromDB]) => {
  //       if (maxIdFromDB) {
  //         maxId = maxIdFromDB;
  //       }
  //       if (budgetDetailFromDB && budgetDetailFromDB.length > 0) {
  //         isFromDB = true;
  //       }

  //       return budgetDetailFromDB;
  //     }),
  //     switchMap((budgetDetailFromDB) => {
  //       return this.get<IBudgetDetail[]>(
  //         'budget/get-budget-details',
  //         false,
  //         { maxId },
  //         Trackers.budgetDetail,
  //         budgetDetailFromDB.length === 0,
  //       ).pipe(
  //         startWith(budgetDetailFromDB),
  //         filter((i) => i.length > 0),
  //         map((budgetDetail: IBudgetDetail[]) => {
  //           // *********** storing in indexed db ************

  //           // @ts-ignore
  //           budgetDetail = structuredClone(
  //             budgetDetail.filter((i) => i !== undefined),
  //           );
  //           // only do data manipulation when its coming from server
  //           if (isFromDB == false && budgetDetail.length > 0) {
  //             // if data is coming from API then we stringify it and store it in indexed db
  //             this.indexedDb.upsertBulk('budget_detail', budgetDetail);
  //           }

  //           if (isFromDB === true) {
  //             isFromDB = false;
  //           }
  //           return budgetDetail;
  //         }),
  //       );
  //     }),
  //   );
  // }

  public getBudgetDetail(): Observable<IBudgetDetail[]> {
    return from(this.indexedDb.getMaxId('budget_detail')).pipe(
      switchMap((maxIdFromDB) => {
        return this.get<IBudgetDetail[]>(
          'budget/get-budget-details',
          false,
          { maxId: maxIdFromDB },
          Trackers.budgetDetail,
          maxIdFromDB === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((budgetDetail: IBudgetDetail[]) => {
            // *********** storing in indexed db ************
            // @ts-ignore
            budgetDetail = structuredClone(
              budgetDetail.filter((i) => i !== undefined),
            );
            this.indexedDb.upsertBulk('budget_detail', budgetDetail);
            return budgetDetail;
          }),
        );
      }),
    );
  }
}
