import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BudgetService } from '../../services/budget.service';
import {
  GetBudgetDetailAction,
  GetBudgetDetailSuccessAction,
  ResetBudgetDetailStore,
} from '../actions/budget-detail.action';
import { of } from 'rxjs';
import { exhaustMap, filter, map, catchError } from 'rxjs/operators';
import { ResetStore } from 'src/app/store/reset/reset.action';

@Injectable({
  providedIn: 'root',
})
export class BudgetEffect {
  actions$: Actions = inject(Actions);
  budgetService: BudgetService = inject(BudgetService);

  getBudgetDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetBudgetDetailAction),
      exhaustMap(() =>
        this.budgetService.getBudgetDetail().pipe(
          filter((budgetDetails) => budgetDetails.length > 0),
          map((budgetDetails) => {
            return GetBudgetDetailSuccessAction({ budgetDetails });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  resetStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetStore),
      map(() => {
        return ResetBudgetDetailStore();
      }),
    ),
  );
}
