import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { iIncome } from '../common/interface';
import { HttpService } from './http.service';
import { Observable, combineLatest, from } from 'rxjs';
import { tap, switchMap, startWith, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class IncomeService extends HttpService {
  userService: UserService = inject(UserService);

  // getIncome(): Observable<iIncome[]> {
  //   let isFromDB = false;
  //   let maxId: number = 0;

  //   return combineLatest([
  //     from(
  //       this.indexedDb.getAllFromDbForTableByUser<iIncome>(
  //         'income',
  //         this.userService.getTrackerPermissionUserByTrackerTypeId(2),
  //       ),
  //     ),
  //     from(this.indexedDb.getMaxId('income')),
  //   ]).pipe(
  //     map(([incomeFromDB, maxIdFromDB]) => {
  //       if (maxIdFromDB) {
  //         maxId = maxIdFromDB;
  //       }
  //       if (incomeFromDB && incomeFromDB.length > 0) {
  //         isFromDB = true;
  //       }

  //       return incomeFromDB;
  //     }),
  //     switchMap((incomeFromDB: iIncome[]) => {
  //       return this.get<iIncome[]>(
  //         'income/get-income',
  //         false,
  //         { maxId },
  //         Trackers.income,
  //         incomeFromDB.length === 0,
  //       ).pipe(
  //         startWith(incomeFromDB),
  //         filter((i) => i.length > 0),
  //         map((income: iIncome[]) => {
  //           // *********** storing in indexed db ************

  //           // @ts-ignore
  //           income = structuredClone(income.filter((i) => i !== undefined));
  //           // only do data manipulation when its coming from server
  //           if (isFromDB == false && income.length > 0) {
  //             income = income.map((i) => {
  //               if (i.tagsArray === undefined && i.tags) {
  //                 // @ts-ignore
  //                 i.tagsArray = i.tags.split('|');
  //               }
  //               return i;
  //             });

  //             // if data is coming from API then we stringify it and store it in indexed db
  //             this.indexedDb.upsertBulk('income', income);
  //           }

  //           if (isFromDB === true) {
  //             isFromDB = false;
  //           }
  //           return income;
  //         }),
  //         map((j) => {
  //           return j.map((i) => {
  //             if (i.tagsArray === undefined && i.tags) {
  //               // @ts-ignore
  //               i.tagsArray = i.tags.split('|');
  //             }
  //             return i;
  //           });
  //         }),
  //       );
  //     }),
  //   );
  // }

  getIncome(): Observable<iIncome[]> {
    return from(this.indexedDb.getMaxId('income')).pipe(
      switchMap((maxIdFromDB) => {
        return this.get<iIncome[]>(
          'income/get-income',
          false,
          { maxId: maxIdFromDB },
          Trackers.income,
          maxIdFromDB === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((income: iIncome[]) => {
            // @ts-ignore
            income = structuredClone(income.filter((i) => i !== undefined)).map(
              (i) => {
                if (i.tagsArray === undefined && i.tags) {
                  // @ts-ignore
                  i.tagsArray = i.tags.split('|');
                }
                return i;
              },
            );

            this.indexedDb.upsertBulk('income', income);
            return income;
          }),
        );
      }),
    );
  }

  addIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/add-income', income).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
        return data;
      }),
    );
  }

  updateIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/update-income', income).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
        return data;
      }),
    );
  }

  deleteIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/delete-income', income).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
        return data;
      }),
    );
  }

  getExtraInfoObj(extraInfo: string) {
    try {
      return JSON.parse(extraInfo);
    } catch (error) {
      return extraInfo;
    }
  }
}
