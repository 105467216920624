import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-loader',
  templateUrl: './module-loader.component.html',
  styleUrls: ['./module-loader.component.scss'],
})
export class ModuleLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
