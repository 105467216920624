import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { Trackers } from '../common/enum';
import { IGroupTags } from '../common/interface';
import { ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { IndexedDBService } from './indexedDb.service';
import { Observable, combineLatest, from } from 'rxjs';
import { tap, switchMap, startWith, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GroupTagsService extends HttpService {
  expenseEngine: ExpenseEngineService = inject(ExpenseEngineService);

  // getGroupTags(trackerTypeId: number): Observable<IGroupTags[]> {
  //   let isFromDB = false;
  //   let maxId: number = 0;
  //   return combineLatest([
  //     from(this.indexedDb.getAllFromDbForTableByUser<IGroupTags>('group_tags')),
  //     from(this.indexedDb.getMaxId('group_tags')),
  //   ]).pipe(
  //     map(([groupTagsFromDB, maxIdFromDB]) => {
  //       if (maxIdFromDB) {
  //         maxId = maxIdFromDB;
  //       }
  //       if (groupTagsFromDB && groupTagsFromDB.length > 0) {
  //         isFromDB = true;
  //       }
  //       return groupTagsFromDB;
  //     }),
  //     switchMap((groupTagsFromDB: IGroupTags[]) => {
  //       return this.get<IGroupTags[]>(
  //         'group-tags/get-group-tags',
  //         false,
  //         { trackerTypeId: trackerTypeId, maxId },
  //         Trackers.expense,
  //         groupTagsFromDB.length === 0
  //       ).pipe(
  //         startWith(groupTagsFromDB),
  //         filter((i) => i.length > 0),
  //         map((groupTags: IGroupTags[]) => {
  //           // *********** storing in indexed db ************

  //           // @ts-ignore
  //           groupTags = structuredClone(
  //             groupTags.filter((i) => i !== undefined)
  //           );

  //           groupTags.forEach((groupTag) => {
  //             if (groupTag.tagsArray) return;
  //             groupTag.tagsArray = groupTag.tags?.split('|');
  //           });

  //           // only do data manipulation when its coming from server
  //           if (isFromDB == false && groupTags.length > 0) {
  //             // if data is coming from API then we stringify it and store it in indexed db
  //             this.indexedDb.upsertBulk('group_tags', groupTags);
  //           }

  //           if (isFromDB === true) {
  //             isFromDB = false;
  //           }
  //           return groupTags;
  //         })
  //       );
  //     })
  //   );
  // }

  getGroupTags(trackerTypeId: number): Observable<IGroupTags[]> {
    return from(this.indexedDb.getMaxId('group_tags')).pipe(
      switchMap((maxIdFromDB) => {
        return this.get<IGroupTags[]>(
          'group-tags/get-group-tags',
          false,
          { trackerTypeId: trackerTypeId, maxId: maxIdFromDB },
          Trackers.expense,
          maxIdFromDB === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((groupTags: IGroupTags[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            groupTags = structuredClone(
              groupTags.filter((i) => i !== undefined),
            );

            groupTags.forEach((groupTag) => {
              if (groupTag.tagsArray) return;
              groupTag.tagsArray = groupTag.tags?.split('|');
            });

            this.indexedDb.upsertBulk('group_tags', groupTags);
            return groupTags;
          }),
        );
      }),
    );
  }

  createGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/create-group-tags',
      formData,
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      }),
    );
  }

  updateGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/update-group-tags',
      formData,
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      }),
    );
  }

  deleteGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/delete-group-tags',
      formData,
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      }),
    );
  }
}
