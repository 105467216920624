import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map } from 'rxjs/operators';
import { TagsService } from '../../services/tags.service';
import {
  GetIgnoreTagsAction,
  LoadIgnoreTagsAction,
  CreateIgnoreTagsAction,
  CreateIgnoreTagsSuccessAction,
  UpdateIgnoreTagsAction,
  UpdateIgnoreTagsSuccessAction,
  ResetIgnoreTagsStore,
} from '../actions/ignore-tags.action';
import { ResetStore } from 'src/app/store/reset/reset.action';

@Injectable({
  providedIn: 'root',
})
export class IgnoreTagsEffect {
  actions$: Actions = inject(Actions);
  tagsService: TagsService = inject(TagsService);

  getIgnoreTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetIgnoreTagsAction),
      exhaustMap(() =>
        this.tagsService.getIgnoreTags().pipe(
          filter((tags) => tags.length > 0),
          map((tags) => LoadIgnoreTagsAction({ tags })),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  createIgnoreTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateIgnoreTagsAction),
      exhaustMap((action) =>
        this.tagsService.createIgnoreTag(action.ignoreTag).pipe(
          map((tag) => {
            return CreateIgnoreTagsSuccessAction({ tag: tag });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  updateIgnoreTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateIgnoreTagsAction),
      exhaustMap((action) =>
        this.tagsService.updateIgnoreTag(action.ignoreTag).pipe(
          map((tag) => {
            return UpdateIgnoreTagsSuccessAction({ ignoreTag: tag });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  resetStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetStore),
      map(() => {
        return ResetIgnoreTagsStore();
      }),
    ),
  );
}
