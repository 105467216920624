import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { Trackers } from '../../common/enum';
import { GroupTagsService } from '../../services/group-tags.service';
import {
  AddGroupTagsAction,
  AddGroupTagsSuccessAction,
  DeleteGroupTagsAction,
  DeleteGroupTagsSuccessAction,
  GetGroupTagsSuccessAction,
  GroupTagsActionTypes,
  ResetGroupTagsStore,
  UpdateGroupTagsAction,
  UpdateGroupTagsSuccessAction,
} from '../actions/group-tags.action';
import { IGroupTags } from '../../common/interface';
import { ResetStore } from 'src/app/store/reset/reset.action';

@Injectable({
  providedIn: 'root',
})
export class GroupTagsEffects {
  actions$: Actions = inject(Actions);
  groupTagsService: GroupTagsService = inject(GroupTagsService);

  getGroupTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupTagsActionTypes.GROUP_TAG_LIST_GET),
      exhaustMap(() =>
        this.groupTagsService.getGroupTags(Trackers.expense).pipe(
          filter((groups) => groups.length > 0),
          map((groups: IGroupTags[]) => {
            return GetGroupTagsSuccessAction({ groupTags: groups });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  addGroupTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddGroupTagsAction),
      switchMap((params) =>
        this.groupTagsService.createGroupTags(params.groupTag).pipe(
          map((groupTag: any) => {
            return AddGroupTagsSuccessAction({ groupTag });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  updateGroupTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateGroupTagsAction),
      switchMap((params) =>
        this.groupTagsService.updateGroupTags(params.groupTag).pipe(
          map((groups: any) => {
            return UpdateGroupTagsSuccessAction({ groupTag: groups });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  deleteGroupTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteGroupTagsAction),
      switchMap((params) =>
        this.groupTagsService.deleteGroupTags(params.groupTag).pipe(
          map((groups: any) => {
            return DeleteGroupTagsSuccessAction({ groupTag: groups });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  resetStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetStore),
      map(() => {
        return ResetGroupTagsStore();
      }),
    ),
  );
}
