import { Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { iReoccurringTracks } from '../common/interface';
import { HttpService } from './http.service';
import { Observable, combineLatest, from } from 'rxjs';
import { tap, switchMap, startWith, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReoccurringExpensesOrIncomeService extends HttpService {
  // getExpensesOrIncome(): Observable<iReoccurringTracks[]> {
  //   let isFromDB = false;
  //   let maxId: number = 0;
  //   return combineLatest([
  //     this.indexedDb.getAllFromDbForTableByUser<iReoccurringTracks>(
  //       'reoccurring_tracks',
  //     ),
  //     from(this.indexedDb.getMaxId('reoccurring_tracks')),
  //   ]).pipe(
  //     map(([reoccurringTracksFromDB, maxIdFromDB]) => {
  //       if (maxIdFromDB) {
  //         maxId = maxIdFromDB;
  //       }
  //       if (reoccurringTracksFromDB && reoccurringTracksFromDB.length > 0) {
  //         isFromDB = true;
  //       }
  //       return reoccurringTracksFromDB;
  //     }),
  //     switchMap((reoccurringTracksFromDB: iReoccurringTracks[]) => {
  //       return this.get<iReoccurringTracks[]>(
  //         'reoccurring-tags/get-reoccurring-tags',
  //         false,
  //         { maxId },
  //         Trackers.reoccurringTrack,
  //         reoccurringTracksFromDB.length === 0,
  //       ).pipe(
  //         startWith(reoccurringTracksFromDB),
  //         filter((i) => i.length > 0),
  //         map((reoccurringTracks: iReoccurringTracks[]) => {
  //           // *********** storing in indexed db ************

  //           // @ts-ignore
  //           reoccurringTracks = structuredClone(
  //             reoccurringTracks.filter((i) => i !== undefined),
  //           );
  //           // only do data manipulation when its coming from server
  //           if (isFromDB == false && reoccurringTracks.length > 0) {
  //             // if data is coming from API then we stringify it and store it in indexed db
  //             this.indexedDb.upsertBulk(
  //               'reoccurring_tracks',
  //               reoccurringTracks,
  //             );
  //           }

  //           if (isFromDB === true) {
  //             isFromDB = false;
  //           }
  //           return reoccurringTracks;
  //         }),
  //         map((j) =>
  //           j.map((i) => {
  //             if (i.tagsArray === undefined && i.tags) {
  //               // @ts-ignore
  //               i.tagsArray = i.tags.split('|');
  //             }
  //             return i;
  //           }),
  //         ),
  //       );
  //     }),
  //   );
  // }

  getExpensesOrIncome(): Observable<iReoccurringTracks[]> {
    let isFromDB = false;
    let maxId: number = 0;
    return from(this.indexedDb.getMaxId('reoccurring_tracks')).pipe(
      switchMap((maxIdFromDB) => {
        return this.get<iReoccurringTracks[]>(
          'reoccurring-tags/get-reoccurring-tags',
          false,
          { maxId: maxIdFromDB },
          Trackers.reoccurringTrack,
          maxIdFromDB === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((reoccurringTracks: iReoccurringTracks[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            reoccurringTracks = structuredClone(
              reoccurringTracks.filter((i) => i !== undefined),
            ).map((i) => {
              if (i.tagsArray === undefined && i.tags) {
                // @ts-ignore
                i.tagsArray = i.tags.split('|');
              }
              return i;
            });

            this.indexedDb.upsertBulk('reoccurring_tracks', reoccurringTracks);

            return reoccurringTracks;
          }),
        );
      }),
    );
  }

  addExpensesOrIncome(
    track: iReoccurringTracks,
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/add-reoccurring-tags',
      track,
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      }),
    );
  }

  updateExpensesOrIncome(
    track: iReoccurringTracks,
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/update-reoccurring-tags',
      track,
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      }),
    );
  }

  deleteExpensesOrIncome(
    track: iReoccurringTracks,
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/delete-reoccurring-tags',
      track,
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      }),
    );
  }
}
