import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { iTrack } from '../common/interface';
import { Expense, ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { Observable, combineLatest, from } from 'rxjs';
import { switchMap, startWith, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class TrackService extends HttpService {
  expenseEngine: ExpenseEngineService = inject(ExpenseEngineService);
  userService: UserService = inject(UserService);

  // getTracks(trackerTypeId: number): Observable<iTrack[]> {
  //   let isFromDB = false;
  //   let maxId: number = 0;
  //   return combineLatest([
  //     from(
  //       this.indexedDb.getAllFromDbForTableByUser<iTrack>(
  //         'tracker',
  //         this.userService.getTrackerPermissionUserByTrackerTypeId(1)
  //       )
  //     ),
  //     from(this.indexedDb.getMaxId('tracker')),
  //   ]).pipe(
  //     map(([trackersFromDB, maxIdFromDB]) => {
  //       if (maxIdFromDB) {
  //         maxId = maxIdFromDB;
  //       }
  //       if (trackersFromDB && trackersFromDB.length > 0) {
  //         isFromDB = true;
  //       }
  //       return trackersFromDB;
  //     }),
  //     switchMap((trackersFromDB: iTrack[]) => {
  //       return this.get<iTrack[]>(
  //         'track/get-all-track-by-tracker-type',
  //         false,
  //         { trackerTypeId, maxId },
  //         Trackers.expense,
  //         trackersFromDB.length === 0
  //       ).pipe(
  //         startWith(trackersFromDB),
  //         filter((i) => i.length > 0),
  //         map((trackers) => {
  //           // @ts-ignore
  //           trackers = structuredClone(trackers.filter((i) => i !== undefined));
  //           // only do data manipulation when its coming from server
  //           if (isFromDB == false && trackers.length > 0) {
  //             // if data is coming from API then we stringify it and store it in indexed db
  //             this.indexedDb.upsertBulk('tracker', trackers);
  //             this.expenseEngine.setExpenses((trackers || []) as Expense[]);
  //           }
  //           if (isFromDB === true) {
  //             isFromDB = false;
  //           }
  //           return trackers;
  //         })
  //       );
  //     })
  //   );
  // }

  getTracks(trackerTypeId: number): Observable<iTrack[]> {
    return from(this.indexedDb.getMaxId('tracker')).pipe(
      switchMap((maxId: number) => {
        return this.get<iTrack[]>(
          'track/get-all-track-by-tracker-type',
          false,
          { trackerTypeId, maxId },
          Trackers.expense,
          maxId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((trackers) => {
            // @ts-ignore
            const tracks = structuredClone(
              trackers.filter((i) => i !== undefined),
            );

            if (tracks.length > 0) {
              this.indexedDb.upsertBulk('tracker', tracks);
              this.expenseEngine.setExpenses(tracks as Expense[]);
            }

            return tracks;
          }),
        );
      }),
    );
  }

  addExpenses(expense: iTrack): Observable<iTrack> {
    return this.post<iTrack, iTrack>('track/add-track', expense).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags!.split('|');

        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
        return data;
      }),
    );
  }

  updateExpense(expense: iTrack): Observable<iTrack> {
    return this.post<iTrack, iTrack>('track/update-track', expense).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags!.split('|');
        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
        return data;
      }),
    );
  }

  deleteExpense(expense: iTrack): Observable<iTrack> {
    return this.post<iTrack, iTrack>('track/delete-track', expense).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags!.split('|');
        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
        return data;
      }),
    );
  }

  getExtraInfoObj(extraInfo: string) {
    try {
      return JSON.parse(extraInfo);
    } catch (error) {
      return extraInfo;
    }
  }
}
