import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IconUploadService } from '../../services/icon-upload.service';

import {
  CreateIconUploadAction,
  CreateIconUploadSuccessAction,
  DeleteIconUploadAction,
  DeleteIconUploadSuccessAction,
  IconUploadActionTypes,
  LoadIconUploadAction,
  ResetIconUploadStore,
  UpdateIconUploadAction,
  UpdateIconUploadSuccessAction,
} from '../actions/icon-upload.action';
import { of } from 'rxjs';
import { exhaustMap, filter, map, catchError, switchMap } from 'rxjs/operators';
import { ResetStore } from 'src/app/store/reset/reset.action';

@Injectable({
  providedIn: 'root',
})
export class IconUploadEffects {
  actions$: Actions = inject(Actions);
  iconUploadService: IconUploadService = inject(IconUploadService);

  getIcons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IconUploadActionTypes.UPLOAD_ICON_LIST_GET),
      exhaustMap(() =>
        this.iconUploadService.getIcons().pipe(
          filter((icons) => icons.length > 0),
          map((icons) => {
            return LoadIconUploadAction({ icons: icons });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  addIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateIconUploadAction),
      switchMap((param) =>
        this.iconUploadService.createIcon(param.icon).pipe(
          map((icon: any) => {
            return CreateIconUploadSuccessAction({ icon });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  updatedIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateIconUploadAction),
      switchMap((param) =>
        this.iconUploadService.updateIcon(param.icon).pipe(
          map((icon: any) => {
            return UpdateIconUploadSuccessAction({ icon });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  deleteIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteIconUploadAction),
      switchMap((param) =>
        this.iconUploadService.deleteIcon(param.icon).pipe(
          map((icon: any) => {
            return DeleteIconUploadSuccessAction({ icon });
          }),
          catchError((error) => of(error)),
        ),
      ),
    ),
  );

  resetStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetStore),
      map(() => {
        return ResetIconUploadStore();
      }),
    ),
  );
}
